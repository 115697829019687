import React from 'react';
import PropTypes from 'prop-types';
import { Clickable } from 'phoenix-components';
import AddButton from 'components/store/common/AddButton';
import { getDiscount, getProductImage } from 'components/store/utils';
import { Grid } from '@mui/material';
import { useRouter } from 'next/router';
import ProductColorsSalvia from './ProductColorsSalvia';
import { useIsCustomDomain } from 'contexts/profile';
import { useLanguageLoader } from 'contexts/language';
import ProductTags from 'components/store/common/ProductTags';
import styles from './ProductCard.module.css';
import GetPrice from '../common/GetPrice';

export function ProductCard({
  shop,
  product
}) {

  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();
  const lang = useLanguageLoader();

  const img = getProductImage(product);

  const isOOS = product?.status === 'out of stock';

  const discount = getDiscount(product);

  const onClick = (_, value) => {
    let url = isCustomDomain ? `/${product.slug}` : `/${product.shopSlug}/${product.slug}`;
    if(!window.isWebView) {
      if (value) {
        url = url + `?color=${value}`;
      }
      window.open(url,'_blank');
    }
    else
      router.push(url);
  };

  return (
    <Grid item xs={12} md={4}>
      <div className={styles.container}>
        <Clickable className={styles.click} onClick={onClick}>
          <div className={styles.imgContainer}>
            <img loading={"lazy"} className={styles.img} src={img} alt="" />
            {isOOS && (
              <span className={styles.oos}>{lang('buttons.soldOut1')}</span>
            )}
          </div>          
          <div className={styles.name}>
            {product.title}
          </div>
          <div className={styles.price}>
            <div className={styles.amount}>
              <span>
                {shop.currency}
              </span>
              {' '}
              <GetPrice price={product.discountedAmount} />
            </div>
            {discount > 0 && (
              <div className={styles.discountContainer}>
                <div className={styles.originalAmount}>
                  {shop.currency}
                  {' '}
                  {product.amount}
                </div>
                <div className={styles.discount}>
                  {discount}
                % OFF
                </div>
              </div>
            )}
          </div>
          <div className={styles.colors}>
            <ProductColorsSalvia onClick={onClick} product={product} />
          </div>
          <ProductTags product={product} shop={shop} />
        </Clickable>
        <div className={"spacer"}></div>
        <AddButton themeName={"salvia"} shop={shop} product={product} />
      </div>
    </Grid>
  );

}

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  shop: PropTypes.object.isRequired,
};

ProductCard.defaultProps = {};

export default ProductCard;
