import PropTypes from 'prop-types';
import React from 'react';
import LazyLoad from 'react-lazyload';
import ViewAllCatalog from 'components/store/salvia/ViewAllCatalog';

function ViewAllCatalogLazy({
  shop,
  name,
  component,
  size,
  props,
  height,
  containerClass,
  getPropsFunc,
}) {
  return (
    <LazyLoad once={true} height={height} offset={100}>
      <ViewAllCatalog
        containerClass={containerClass}
        getPropsFunc={getPropsFunc}
        key={name}
        props={props}
        height={height}
        shop={shop}
        name={name}
        component={component}
        size={size}
      />
    </LazyLoad>
  );
}

ViewAllCatalogLazy.propTypes = {
  shop: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  component: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  props: PropTypes.array,
  height: PropTypes.number,
  containerClass: PropTypes.string,
  getPropsFunc: PropTypes.func,
};

ViewAllCatalogLazy.defaultProps = {
  props: [],
  containerClass: '',
  getPropsFunc: null,
};

export default ViewAllCatalogLazy;
