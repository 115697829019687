import PropTypes from 'prop-types';
import cx from 'classnames';
import { Clickable } from 'phoenix-components';
import { Avatar } from '@mui/material';
import { randColor } from 'ui/lib/colors';
import { ViewAllIconSalvia } from 'components/shared/Icons/theme';
import React from 'react';
import { useRouter } from 'next/router';
import { useIsCustomDomain } from 'contexts/profile';
import { useLanguageLoader } from 'contexts/language';
import styles from './common.module.css';
import ShopUtils from "../../../ui/lib/shopUtils";

export default function Catalogs({
  catalogs,
  shop,
  width
}) {
  const lang = useLanguageLoader();
  const router = useRouter();
  const isCustomDomain = useIsCustomDomain();

  const openCatalog = name => () => {

    router.push(
      {
        pathname: isCustomDomain ? '/catalogs' : `/${shop?.slug}/catalogs`,
        query: {
          catalog: name
        },
      }
    );

  };

  if (catalogs.length <= 0 || !width) {

    return null;

  }
  const feedTitle = ShopUtils.getTitle(shop, 'collections');
  const savedTitle = 'store.headings.collections' === feedTitle ?
    'store.headings.collections' : feedTitle;

  return (
    <>
      <div className={cx('mobile')}>
        <div className={styles.heading}>{savedTitle}</div>
        <div
          className={cx(styles.catalogs, { 'vertical-center': catalogs.length === 1 })}
        >
          {catalogs.slice(0, 4)
            .map(catalog => (
              <Clickable
                key={catalog.label}
                className={styles.catalog}
                onClick={openCatalog(catalog.value)}
              >
                <Avatar
                  className={cx(styles.catalogImg, {
                    [styles.noCatalogImg]: !catalog.img,
                  })}
                  style={{
                    background: randColor(catalog.label),
                  }}
                  src={catalog.img}
                  imgProps={{
                    loading:'lazy',
                  }}
                  alt=""
                >
                  {catalog?.label ? catalog.label.substring(0, 2) : 'NA'}
                </Avatar>
                <div className={styles.catalogLabel}>
                  <div>
                    {catalog.label}
                  </div>
                  {' '}
                  <div className={styles.viewAll}>
                    <ViewAllIconSalvia />
                  </div>
                </div>
              </Clickable>
            ))}
          <div className={styles.viewButton} >
            {catalogs.length > 4 && (
              <Clickable
                className={cx(styles.viewAllbtn)}
                onClick={() => {
                  router.push(isCustomDomain ? '/catalogList' : `${shop?.slug}/catalogList`);
                }}
              >
                <span>
                  {lang('buttons.viewAll')}
                </span>
              </Clickable>
            )}
          </div>
        </div>
      </div>
      <div className="desktop">
        <div className={styles.heading}>{savedTitle}</div>
        <div className={styles.catalogs}>
          {catalogs.slice(0, 4)
            .map(catalog => (
              <Clickable
                key={catalog.value}
                className={styles.catalog}
                onClick={openCatalog(catalog.value)}
              >
                <Avatar
                  className={cx(styles.catalogImg, {
                    [styles.noCatalogImg]: !catalog.img,
                  })}
                  style={{
                    background: randColor(catalog.label),
                  }}
                  src={catalog.img}
                  imgProps={{
                    loading:'lazy',
                  }}
                  alt=""
                >
                  {catalog?.label ? catalog.label.substring(0, 2) : 'NA'}
                </Avatar>
                <div className={styles.catalogLabel}>
                  {catalog.label}
                  {' '}
                  <ViewAllIconSalvia />
                </div>
              </Clickable>
            ))}

          <div className={styles.viewButton} >
            {catalogs.length > 4 && (
              <Clickable
                className={cx(styles.viewAllbtn)}
                onClick={() => {
                  router.push(isCustomDomain ? '/catalogList' : `${shop?.slug}/catalogList`);
                }}
              >
                <span>
                  {lang('buttons.viewAll')}
                </span>
              </Clickable>
            )}
          </div>
        </div>
      </div>
    </>
  );

}

Catalogs.propTypes = {
  catalogs: PropTypes.array.isRequired,
  shop: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
};
