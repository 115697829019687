import styles from './ProductColorsSalvia.module.css'
import PropTypes from 'prop-types';
import cx from 'classnames';

export default function ProductColorsSalvia({ product, onClick }) {
  const colors = product?.colors || [];
  return (
    <div className='hor-center marginSTopBottom'>
      {colors.slice(0, 3).map((x, i) => (
        <div
          onMouseEnter={(e) => {
            if (i === 0) {
              return;
            }
            const style = e.target.style;
            style.outlineColor = x.hex;
            style.outlineOffset = '2px';
            style.outlineStyle = 'solid';
          }}
          onMouseLeave={(e) => {
            if (i === 0) {
              return;
            }
            const style = e.target.style;
            style.outlineColor = 'transparent';
            style.outlineOffset = '0';
            style.outlineStyle = 'solid';
          }}
          key={i}
          className={cx(styles.outer, { [styles.outline]: i === 0 })}
          style={{
            background: x.hex,
            ...(i === 0 ? { outlineColor : x.hex } : {})
          }}
          onClick={(e) => onClick(e, x._id)}
        >
        </div>
      ))}
      {colors.slice(3).length > 0 && (
        <div
          className={cx(styles.link)}
          onClick={onClick}
        >
          {`+${colors.slice(3).length}`}
        </div>
      )}
    </div>
  )
}

ProductColorsSalvia.propTypes = {
  product: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
}
