import PropTypes from 'prop-types';
import cx from 'classnames';
import { Clickable } from 'phoenix-components';
import React, { useState } from 'react';
import { useIsDesktop } from 'contexts/profile';
import { useIsCustomDomain } from 'contexts/profile';
import { WhitePrevIcon, WhiteNextIcon } from 'components/shared/Icons/Shop';
import { useRouter } from 'next/router';
import { Timer } from  '../Timer'
import styles from './common.module.css';
import { useKeenSlider } from 'keen-slider/react';

export default function Templates({
  shop,
  templates,
}) {
  const isDesktop = useIsDesktop();


  const router = useRouter();

  const isCustomDomain = useIsCustomDomain();
  const [currentSlide, setCurrentSlide] = useState(0);

  const perView = isDesktop ? 1 : 1;

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      slides: {
        perView: perView,
        origin: templates.length < perView ? 'center' : undefined,
      }
    },
    [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, 4000)
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      },
    ]

  );

  if (templates.length <= 0) {

    return null;

  }

  return (
    <div className={styles.templates}>
      <div
        ref={sliderRef}
        className={'keen-slider'}
      >
        {templates.map((tpl, i) => (
          <div key={i} className={cx({ [styles.single]: templates.length === 1 }, 'keen-slider__slide')}>
            <Clickable
              className={styles.template}
              onClick={() => {

                const isCatalog = tpl.type === 'catalog';
                if (isCatalog) {

                  router.push(
                    {
                      pathname: isCustomDomain ? '/catalogs' : `/${shop?.slug}/catalogs`,
                      query: {
                        catalogs: tpl.items
                      },
                    }
                  );
                  return;

                }
                router.push(
                  {
                    pathname: isCustomDomain ? `/template/${tpl._id}` : `/${shop?.slug}/template/${tpl._id}`,
                  }
                );

              }}
            >
              <div
                className={styles.templateImage}
                style={{ backgroundImage: `url(${tpl?.image})` }}
              >
                {isDesktop && (
                  <Clickable
                    className={cx(styles.slideButton, {
                      'opacity-50': currentSlide === 0,
                    })}
                    onClick={(e) => {

                      e.stopPropagation();
                      instanceRef.current.prev();

                    }}
                  >
                    <WhitePrevIcon />
                  </Clickable>
                )}
                <div className={styles.tplTitle}>
                  {tpl.title}
                </div>
                <div className={styles.tplDesc}>
                  {tpl.description}
                </div>
                <>
                  <Timer shop={shop} timerId={tpl.timerId} />
                </>
                {isDesktop && (
                  <Clickable
                    className={cx(styles.slideButton, {
                      'opacity-30': currentSlide === templates.length - 1,
                    })}
                    onClick={(e) => {

                      e.stopPropagation();
                      instanceRef.current.next();

                    }}
                  >
                    <WhiteNextIcon />
                  </Clickable>
                )}
              </div>
            </Clickable>
          </div>
        ))}
      </div>
    </div>
  );

}

Templates.propTypes = {
  shop: PropTypes.object.isRequired,
  templates: PropTypes.array,
};

Templates.defaultProps = {
  templates: [],
};
