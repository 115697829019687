import { defaultProps, props } from 'containers/themes/props/store';
import TopBarSalvia from 'components/store/salvia/TopBarSalvia';
import React from 'react';
import NoOrders from 'components/home/NoOrders';
import { useInfiniteShopProducts } from 'hooks/product';
import { useDeviceWidth } from 'hooks/removeJss';
import Catalogs from 'components/store/salvia/Catalogs';
import Templates from 'components/store/salvia/Templates';
import ViewAll from 'components/store/salvia/ViewAll';
import { useLanguageLoader } from 'contexts/language';
import { ProductCard } from 'components/store/salvia/ProductCard';
import styles from './store.module.css';
import ViewAllCatalogLazy from 'components/store/salvia/ViewAllCatalogLazy';
import ShopUtils from 'ui/lib/shopUtils';
const emptyProducts = '/images/store/noProducts.svg'
import DisablePopup from 'components/store/common/DisablePopup';
import { useIsDesktop } from 'contexts/profile';


function SalviaStore({
  shop,
  savedFeed,
  templates,
  catalogList,
}) {
  const width = useDeviceWidth();
  const [products] = useInfiniteShopProducts(savedFeed);
  const lang = useLanguageLoader();
  const isDesktop = useIsDesktop();
  const showTemplate = ShopUtils.showBanner(shop);
  const featureProductsEnabled = ShopUtils.isSectionEnabled(shop, 'featuredProducts');
  const collectionsEnabled = ShopUtils.isSectionEnabled(shop, 'collections');
  const productsEnabled = ShopUtils.isSectionEnabled(shop, 'products');
  const feedTitleTime = ShopUtils.getTitle(shop, 'featuredProducts');
  const savedTitleTime = feedTitleTime === "Featured Products" ? lang('store.headings.bestSellers') : feedTitleTime;
  const layoutConfig = shop?.uiConfig?.layoutConfig;
  const templateIdShopProfile = layoutConfig?.shopProfile?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundShopProfileTemplates = templates?.filter(template => templateIdShopProfile.includes(template._id));
  const templateIdCollections = layoutConfig?.collections?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundCollectionsTemplates = templates?.filter(template => templateIdCollections.includes(template._id));

  const templateIdFeaturedProducts = layoutConfig?.featuredProducts?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundFeaturedProductsTemplates =
      templates?.filter(template => templateIdFeaturedProducts.includes(template._id));

  const templateIdProducts = layoutConfig?.products?.banners?.
    flatMap(banner => Object.values(banner.templateIds)) || [];
  const foundProductsTemplates = templates?.filter(template => templateIdProducts.includes(template._id));

  return (
    <div>
      <DisablePopup blocked={shop?.blocked} />
      <TopBarSalvia shop={shop} catalogs={catalogList} showTabs={false} />
      <div id="shopScroll1" />
      {products.length === 0 && (
        <div className={styles.overflow1}>
          <NoOrders
            title="Shop does not have any products yet!"
            icon={emptyProducts}
          />
        </div>
      )}
      {showTemplate
       &&
       <Templates shop={shop} width={width} templates={foundShopProfileTemplates} />
      }
      {collectionsEnabled
      &&
       <Catalogs catalogs={catalogList} shop={shop} width={width} />
      }
      {showTemplate && <Templates templates={foundCollectionsTemplates} shop={shop}/>}
      {featureProductsEnabled && (
        <ViewAll
          shop={shop}
          products={savedFeed}
          type="featured"
          themeName={"salvia"}
          name={savedTitleTime}
        >
          {savedFeed.slice(0, isDesktop ? 9 : 4)
            .map(product => (
              <ProductCard key={product._id} shop={shop} product={product} />
            ))}
        </ViewAll>
      )}
      {showTemplate && <Templates templates={foundFeaturedProductsTemplates} shop={shop}/>}
      {productsEnabled && (
        <>
          {catalogList.map(catalog => (
            <ViewAllCatalogLazy
              height={600}
              key={catalog.value}
              shop={shop}
              name={catalog.value}
              component={ProductCard}
              size={6}
            />
          ))}
        </>
      )}
      {showTemplate && <Templates templates={foundProductsTemplates} shop={shop}/>}
    </div>
  );
}

SalviaStore.propTypes = props;
SalviaStore.defaultProps = defaultProps;

export default SalviaStore;
